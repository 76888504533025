<template>
  <div class="lg:w-3/4 w-full mb-5">
    <app-loading-page
      :text="$t('general.submitting')"
      :loading="loading"
      class="w-40 h-20 m-auto rounded-md shadow-md"
    />
    <h1 class="text-2xl font-bold">
      {{ $t("business.summary.title") }}
    </h1>
    <p class="mt-4 mb-5">
      {{ $t("business.summary.description") }}
    </p>
    <div class="mb-8">
      <div class="mb-5">
        <span class="span-items">
          <p class="index-rounded">1</p>
          <p>{{ $t("business.main.structure") }}</p>
        </span>
        <div
          v-if="!errors.business_structure"
          class="flex flex-row border-2 border-gray rounded-md justify-between"
        >
          <div class="flex flex-col m-5">
            <div v-if="business.id != null">
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.structure.country_label") }}
                </p>
                <p class="text-gray-600">{{ business.country.name }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.structure.info_label") }}
                </p>
                <p class="text-gray-600">{{ business.name }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.structure.address_label") }}
                </p>
                <p class="text-gray-600">
                  {{ business.address_1 }}
                  <br />
                  {{ business.address_2 }}
                  <br />
                  {{ business.postcode }}, {{ business.city }},
                  {{ business.state?.name ?? "" }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.structure.type_of_business_label") }}
                </p>
                <p class="text-gray-600">
                  {{ business.type_of_business?.description }}
                </p>
              </div>
            </div>
            <div v-else>
              <p>
                {{
                  $t("business.summary.missing_required_business_information")
                }}
              </p>
            </div>
          </div>
          <div
            class="px-5 py-5"
            v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
          >
            <button
              class="edit-button"
              v-on:click="routeTo('/master-business/business/structure')"
            >
              <PencilIcon class="p-1.5" />
            </button>
          </div>
        </div>
        <div v-else>
          <app-alert-error-action
            :message="errors.business_structure[0]"
            @onClick="routeTo('/master-business/business/structure')"
          />
        </div>
      </div>

      <div class="mb-5">
        <span class="span-items">
          <p class="index-rounded">2</p>
          <p>{{ $t("business.main.details") }}</p>
        </span>
        <div
          v-if="!errors.business_detail"
          class="flex flex-row border-2 border-gray rounded-md justify-between"
        >
          <div class="flex flex-col m-5 justify-center">
            <div v-if="business.detail.id != null">
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.detail.company_label") }}
                </p>
                <p class="text-gray-600">{{ business.detail.company_name }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.summary.detail_company_no") }}
                </p>
                <p class="text-gray-600">
                  {{ business.detail.company_reg_no }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.summary.detail_company_annual_turnover") }}
                </p>
                <p class="text-gray-600">
                  {{ business.detail.company_annual_turnover?.description }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.detail.tax_id") }}
                </p>
                <p class="text-gray-600">
                  {{ business.detail.tax_id }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.summary.detail_activity") }}
                </p>
                <p class="text-gray-600">
                  {{ business.detail.business_activity }}
                </p>
              </div>
              <!-- Hide for temporary (for revisit https://app.clickup.com/t/2e0dnyj)
                <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.structure.address_label") }}
                </p>
                <p class="text-gray-600">
                  {{ business.detail.address_1 }}
                  <br />
                  {{ business.detail.address_2 }}
                  <br />
                  {{ business.detail.postcode }}, {{ business.detail.city }},
                  {{ business.detail.state?.name ?? "" }}
                </p>
              </div> -->
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.detail.phone_no_label") }}
                </p>
                <p class="text-gray-600">{{ business.detail.phone_no }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.detail.industry_label") }}
                </p>
                <p class="text-gray-600">
                  {{ business.detail.industry?.name }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.detail.website_label") }}
                </p>
                <p class="text-gray-600">{{ business.detail.website }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.detail.product_desc_label") }}
                </p>
                <p class="text-gray-600">{{ business.detail.description }}</p>
              </div>
            </div>

            <div v-else>
              <p>
                {{
                  $t("business.summary.missing_required_business_information")
                }}
              </p>
            </div>
          </div>
          <div
            class="px-5 py-5"
            v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
          >
            <button
              class="edit-button"
              v-on:click="routeTo('/master-business/business/detail')"
            >
              <PencilIcon class="p-1.5" />
            </button>
          </div>
        </div>
        <div v-else>
          <app-alert-error-action
            :message="errors.business_detail[0]"
            @onClick="routeTo('/master-business/business/detail')"
          />
        </div>
      </div>
      <div class="mb-5">
        <span class="span-items">
          <p class="index-rounded">3</p>
          <p>{{ $t("business.main.representative") }}</p>
        </span>
        <div
          v-if="!errors.business_representative"
          class="flex flex-row border-2 border-gray rounded-md justify-between"
        >
          <div class="flex flex-col justify-center m-5">
            <div v-if="business.representative.id != null">
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.representative.legal_name") }}
                </p>
                <p class="text-gray-600">
                  {{ business.representative.first_name }}
                  {{ business.representative.last_name }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.representative.email") }}
                </p>
                <p class="text-gray-600">{{ business.representative.email }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.representative.designation") }}
                </p>
                <p class="text-gray-600">
                  {{ business.representative.designation }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.representative.date_of_birth") }}
                </p>
                <p class="text-gray-600">
                  {{
                    business.representative.date_of_birth
                      ? $moment(
                          String(business.representative.date_of_birth),
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")
                      : "-"
                  }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.representative.home_address") }}
                </p>
                <p class="text-gray-600">
                  {{ business.representative.address_1 }}
                  <br />
                  {{ business.representative.address_2 }}
                  <br />
                  {{ business.representative.postcode }},
                  {{ business.representative.city }},
                  <br />
                  {{ business.representative.state?.name ?? "" }},
                  {{ business.representative.country?.name ?? "" }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.representative.phone_no") }}
                </p>
                <p class="text-gray-600">
                  {{ business.representative.phone_no }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">{{ labelIdentification() }}</p>
                <p class="text-gray-600">
                  {{ business.representative.identification }}
                </p>
              </div>
            </div>
            <div v-else>
              <p>
                {{
                  $t("business.summary.missing_required_business_information")
                }}
              </p>
            </div>
          </div>
          <div
            class="p-5"
            v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
          >
            <button
              class="edit-button"
              v-on:click="routeTo('/master-business/business/representative')"
            >
              <PencilIcon class="p-1.5" />
            </button>
          </div>
        </div>
        <div v-else>
          <app-alert-error-action
            :message="errors.business_representative[0]"
            @onClick="routeTo('/master-business/business/representative')"
          />
        </div>
      </div>
      <div class="mb-5">
        <span class="span-items">
          <p class="index-rounded">4</p>
          <p>{{ $t("business.main.owners") }}</p>
        </span>
        <div
          v-if="!errors.business_owners"
          class="flex flex-row border-2 border-gray rounded-md justify-between"
        >
          <div class="flex flex-col w-3/4 m-5">
            <div v-if="business.owners && business.owners?.length > 0">
              <div v-for="(owner, index) in business.owners" :key="owner.id">
                <div class="relative grid grid-cols-2 gap-4 p-5">
                  <div>
                    <p class="font-bold">
                      {{ $t("business.owner.label_name_of_person") }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.first_name }}
                      {{ owner.last_name }}
                    </p>
                  </div>

                  <div>
                    <p class="font-bold">
                      {{ $t("business.owner.label_percent_ownership") }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.percent_ownership + "%" }}
                    </p>
                  </div>

                  <div>
                    <p class="font-bold">
                      {{ $t("business.owner.label_email") }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.email }}
                    </p>
                  </div>

                  <div>
                    <p class="font-bold">
                      {{ $t("business.owner.date_of_birth") }}
                    </p>
                    <p class="text-gray-600">
                      {{
                        owner.date_of_birth
                          ? $moment(
                              String(owner.date_of_birth),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </p>
                  </div>

                  <div>
                    <p class="font-bold">
                      {{ $t("business.owner.label_phone_no") }}
                    </p>
                    <p class="text-gray-600">{{ owner.phone_no ?? "-" }}</p>
                  </div>

                  <div>
                    <p class="font-bold">
                      {{ owner.identification_type ?? "-" }}
                    </p>
                    <p class="text-gray-600">
                      {{ owner.identification ?? "-" }}
                    </p>
                  </div>

                  <div>
                    <p class="font-bold">
                      {{ $t("business.owner.home_address") }}
                    </p>
                    <p class="text-gray-600" v-if="owner.address_1">
                      {{ owner.address_1 }}
                      <br />
                      {{ owner.address_2 }}
                      <br />
                      {{ owner.postcode }}, {{ owner.city }},
                      {{ owner.state?.name ?? "" }}
                    </p>
                    <p v-else class="text-gray-600">{{ '-' }}</p>
                  </div>
                </div>
                <hr v-if="index + 1 < business.owners.length" class="mb-3" />
              </div>
            </div>
            <div v-else>
              <p>
                {{
                  $t("business.summary.missing_required_business_information")
                }}
              </p>
            </div>
          </div>
          <div
            class="px-5 py-5"
            v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
          >
            <button
              class="edit-button"
              v-on:click="routeTo('/master-business/business/owners')"
            >
              <PencilIcon class="p-1.5" />
            </button>
          </div>
        </div>
        <div v-else>
          <app-alert-error-action
            :message="errors.business_owners[0]"
            @onClick="routeTo('/master-business/business/owners')"
          />
        </div>
      </div>
      <div class="mb-5">
        <span class="span-items">
          <p class="index-rounded">5</p>
          <p>{{ $t("business.main.support_details") }}</p>
        </span>
        <div
          v-if="!errors.business_support_detail"
          class="flex flex-row border-2 border-gray rounded-md justify-between"
        >
          <div class="flex flex-col m-5 justify-center">
            <div v-if="business.support_detail.id != null">
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.support_detail.statement_descriptor") }}
                </p>
                <p class="text-gray-600">
                  {{ business.support_detail.statement_descriptor }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.support_detail.shortened_descriptor") }}
                </p>
                <p class="text-gray-600">
                  {{ business.support_detail.short_descriptor }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.support_detail.support_email") }}
                </p>
                <p class="text-gray-600">
                  {{ business.support_detail.support_email }}
                </p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.support_detail.phone_no") }}
                </p>
                <p class="text-gray-600">
                  {{ business.support_detail.support_tel_no }}
                </p>
              </div>
            </div>
            <div v-else>
              <p>
                {{
                  $t("business.summary.missing_required_business_information")
                }}
              </p>
            </div>
          </div>
          <div
            class="px-5 py-5"
            v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
          >
            <button
              class="edit-button"
              v-on:click="routeTo('/master-business/business/support-details')"
            >
              <PencilIcon class="p-1.5" />
            </button>
          </div>
        </div>
        <div v-else>
          <app-alert-error-action
            :message="errors.business_support_detail[0]"
            @onClick="routeTo('/master-business/business/support-details')"
          />
        </div>
      </div>
      <div class="mb-5">
        <span class="span-items">
          <p class="index-rounded">6</p>
          <p>{{ $t("business.main.bank") }}</p>
        </span>

        <div
          v-if="!errors.business_bank"
          class="flex flex-row border-2 border-gray rounded-md justify-between"
        >
          <div class="flex flex-col m-5 justify-center">
            <div v-if="business.bank.id != null">
              <div class="mb-3">
                <p class="font-bold mb-1">{{ $t("general.bank") }}</p>
                <p class="text-gray-600">{{ business.bank.bank?.name }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.bank_details.account_number") }}
                </p>
                <p class="text-gray-600">{{ business.bank.account_no }}</p>
              </div>
              <div class="mb-3">
                <p class="font-bold mb-1">
                  {{ $t("business.bank_details.account_holder") }}
                </p>
                <p class="text-gray-600">{{ business.bank.account_holder }}</p>
              </div>
            </div>
            <div v-else>
              <p>
                {{
                  $t("business.summary.missing_required_business_information")
                }}
              </p>
            </div>
          </div>
          <div
            class="px-5 py-5"
            v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
          >
            <button
              class="edit-button"
              v-on:click="routeTo('/master-business/business/bank-details')"
            >
              <PencilIcon class="p-1.5" />
            </button>
          </div>
        </div>
        <div v-else>
          <app-alert-error-action
            :message="errors.business_bank[0]"
            @onClick="routeTo('/master-business/business/bank-details')"
          />
        </div>
      </div>
      <div class="mb-5" v-if="errors.business_support_document">
        <span class="span-items">
          <p class="index-rounded">7</p>
          <p>{{ $t("business.main.support_documents") }}</p>
        </span>
        <div>
          <app-alert-error-action
            :message="errors.business_support_document[0]"
            @onClick="routeTo('/master-business/business/support-documents')"
          />
        </div>
      </div>
    </div>

    <div
      v-if="
        business.business_status_id == BUSINESS_STATUS.CREATED ||
        business.business_status_id == BUSINESS_STATUS.AMENDED
      "
    >
      <p>
        {{ $t("business.summary.agreement_description_1") }}
        <a href="https://swipego.io/term-and-condition/">{{
          $t("business.summary.swipe_agreement")
        }}</a>
        {{ $t("business.summary.agreement_description_2") }}
      </p>

      <app-button
        type="submit"
        v-on:click="submitBusiness()"
        :loading="loading"
        class="w-full mt-5"
        :showProceedIcon="true"
        >{{ $t("general.submit") }}</app-button
      >
    </div>
    <div v-else>
      <app-button
        type="button"
        v-on:click="routeTo('/dashboard')"
        :loading="loading"
        class="w-full mt-5"
        :showProceedIcon="true"
        >{{ $t("general.return_homepage") }}</app-button
      >
    </div>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import { PencilIcon } from "@heroicons/vue/solid";
export default {
  components: {
    PencilIcon,
  },
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      message: "test",
      emptyErrors: {},
    };
  },
  methods: {
    routeTo(link) {
      this.$router.push({
        path: link,
        query: {
          business_id: this.business.id,
        },
      });
    },
    labelIdentification() {
      return this.business.representative.identification_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },
    async submitBusiness() {
      this.resetState();

      const businessResult = await this.$store.dispatch(
        "masterBusinessStore/submitMasterBusiness",
        this.business.id
      );
      if (businessResult != null) {
        this.$notify(
          {
            group: "success",
            title: this.$t("business.summary.business_submitted"),
          },
          8000
        );
      } else {
        this.$notify(
          {
            group: "error",
            title: this.$t(
              "business.summary.missing_required_business_information"
            ),
          },
          3000
        );
      }
    },
    resetState() {
      this.$store.commit("masterBusinessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
  computed: {
    loading() {
      return this.$store.getters["masterBusinessStore/loading"];
    },
    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },
    errors() {
      return (
        this.$store.getters["masterBusinessStore/errors"] ?? this.emptyErrors
      );
    },
  },
};
</script>

<style scoped>
.index-rounded {
  @apply w-6 h-6 text-sm grid border-2 border-black rounded-full place-items-center;
}

.span-items {
  @apply space-x-3 flex items-center mb-5;
}

.edit-button {
  @apply flex rounded-md shadow-sm border bg-white px-2.5 py-4 h-8 w-14 items-center hover:bg-gray-100;
}
</style>
